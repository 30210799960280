<template>
  <div>
    <div>
      <el-main class="el_main">
        <el-carousel :interval="3000" arrow="always">
          <el-carousel-item v-for="(item, index ) in urls" :key="index">
            <img v-bind:src="item.url" alt="">
          </el-carousel-item>
        </el-carousel>
      </el-main>
    </div>
    <div class="header">
      <span>一&emsp;&emsp;花&emsp;&emsp;一&emsp;&emsp;世&emsp;&emsp;界&emsp;&emsp;&emsp;&emsp;&emsp;一&emsp;&emsp;念&emsp;&emsp;一&emsp;&emsp;尘&emsp;&emsp;缘</span>
    </div>
    <div class="character">
      <div class="char">
        <img src="../assets/Image/char.jpg" alt="">
        <div class="charf">
          <div lass="charfA">
            <div>
              &emsp;&emsp;"江畔花寻步"团队，汲取杜甫名句"江畔独步寻花"的灵感，创造了一个充满故事性的角色——江小畔。她是一位身着古典汉服的小女孩，头顶花环，轻轻摇曳着花扇，仿佛在江畔边漫步边寻找那些悄然绽放的花朵。
            </div>
            <div>
              &emsp;&emsp;在压花诗词展览中，她引导着参观者们穿越时空，体验中外语言所交织的唯美意境。她的微笑和步伐，充满了温暖和力量，仿佛在诉说着江畔花朵的传奇故事，带领人们沉浸在诗意盎然的江畔，感受花儿们的舞蹈和低语。
            </div>
          </div>
          <div class="charfB">
            美学与文学相伴，非遗与数媒相生
          </div>
        </div>
      </div>

      <div class="charfont">

        <div class="video">
          <video src="../assets/Image/video.mp4" controls width="535" height="310"></video>
        </div>
      </div>
    </div>

    <div class="boutique">
      <span>每 日 精 选 <i class="el-icon-star-off"></i></span>
      <router-link to="/exhibit"><a>了解更多 >></a></router-link>
    </div>

    <div class="image">

      <div class="ima">
        <img src="../assets/Image/a11.jpg" alt="">
      </div>


      <div class="ima">
        <img src="../assets/Image/a12.jpg" alt="">
      </div>

      <div class="ima">
        <img src="../assets/Image/a14.jpg" alt="">
      </div>

      <div class="ima">
        <img src="../assets/Image/a15.jpg" alt="">
      </div>

      <div class="ima">
        <img src="../assets/Image/a16.jpg" alt="">
      </div>

    </div>
    <div class="boutique">
      <span>小 畔 快 讯 <i class="el-icon-document"></i></span>
    </div>

    <div class="message">
      <div class="mes">
        <div class="meg">资 讯</div>
        <template>
          <div class="me">
            <span><router-link to="">【3-15】 江畔花寻步展览将引入江南古风风格，十里红妆不再是梦！</router-link></span>
            <el-divider></el-divider>
            <span><router-link to="">【3-13】 江小畔人物形象征集活动正在火热进行中</router-link></span>
            <el-divider></el-divider>
            <span><router-link to="">【3-10】 压花线上展览将于2023.3.30日进行</router-link></span>
            <el-divider></el-divider>
            <span><router-link to="">【2-29】 压花展区将分为E人区和I人区，也将提供写真、拍摄服务</router-link></span>
            <el-divider></el-divider>
            <span><router-link to="">【2-15】 压花线下展即将开始</router-link></span>
            <el-divider></el-divider>
            <span><router-link to="">【1-10】 江畔花寻步项目正式启动</router-link></span>
            <el-divider></el-divider>
          </div>
        </template>
      </div>
      <div class="act">
        <div class="meg">最 近 活 动 </div>
        <template>
          <div class="me">
            <span><router-link to="">【3-15】南华大学江畔花寻步项目组江小畔人物形象征集活动</router-link></span>
            <el-divider></el-divider>
            <span><router-link to="">【3-08】南华大学江畔花寻步项目组“压花非遗文化海报”制作活动</router-link></span>
            <el-divider></el-divider>
            <span><router-link to="">【2-18】 江畔花寻步项目组压花设计大赛</router-link></span>
            <el-divider></el-divider>
            <span><router-link to="">【2-15】 江畔花寻步项目组压花文创征集活动</router-link></span>
            <el-divider></el-divider>
          </div>
        </template>
      </div>
    </div>

    <div class="bottom">
      <img src="../assets/Image/bottom.png" alt="">
    </div>
  </div>
</template>



<script>
export default {
  methods: {



  },
  data() {
    return {
      //轮播图
      urls: [
        { url: require("E:/Vue项目实战/knurl/src/assets/Image/image2.jpg") },
        { url: require("E:/Vue项目实战/knurl/src/assets/Image/image4.jpg") },
        { url: require("E:/Vue项目实战/knurl/src/assets/Image/image3.png") },
        { url: require("E:/Vue项目实战/knurl/src/assets/Image/image1.jpg") },

      ],
    }
  }
}
</script>

<style lang='less' scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}


//轮播图页面
.el-main {
  padding: 0;
  width: 1400px;
  margin: auto;
}

/deep/.el-carousel__container {
  height: 550px;
}

/deep/ .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: rgb(12, 13, 13);
}

/deep/ .is-active .el-carousel__button {
  background-color: white;
  border-radius: 50%;
}

.header {
  width: 1400px;
  height: 30px;
  line-height: 30px;
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  color: wheat;
  background-color: rgb(69, 85, 74);
  font-family: 'Times New Roman', Times, serif;
}

.character {
  width: 1400px;
  height: 310px;

  margin: auto;
  margin-bottom: 40px;
}

.char {
  width: 830px;
  float: left;
  border: 3px solid;
  border-radius: 8px;
  margin-right: 30px;
  border-color: rgb(193, 208, 189);
  display: flex;
  align-items: center;
}

.char img {
  float: left;
  margin-left: 5px;
  margin-right: 20px;
}

.charfont {
  height: 310px;
  float: left;

}

.charf {
  width: 570px;
  height: 310px;
  float: left;
  font-family: 'Courier New', Courier, monospace;
  font-size: 19px;
  color: #a5541e;
  padding-top: 10px;
}

.charfB {
  height: 60px;
  width: 500px;
  text-align: center;
  line-height: 60px;
  margin: auto;
  font-size: 25px;
  border-radius: 10px;
  margin-top: 30px;
  color: rgb(193, 142, 85);
  background-color: rgb(243, 220, 212);
}

.vidoe {
  height: 310px;
  float: right;
}

.bottom {
  margin: auto;
}

.boutique {
  width: 1400px;
  height: 40px;
  margin: auto;
  line-height: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 3px solid;
  border-color: rgb(193, 142, 85);
}

.boutique span {
  font-size: 18px;
}

.boutique a {
  float: right;
  margin-right: 10px;
  color: #000;
  text-decoration: none;
}

.image {
  width: 1400px;
  height: 260px;
  margin: auto;
}

.ima {
  width: 250px;
  height: 230px;
  display: flex;
  align-items: center;
  float: left;
  margin-right: 30px;
}

.message {
  width: 1400px;
  height: 350px;
  margin: auto;
  margin-bottom: 30px;
}

.mes {
  width: 680px;
  height: 100%;
  margin-right: 20px;
  float: left;
  border: 3px solid;
  border-radius: 7px;
  border-color: rgb(193, 208, 189);
}

.act {
  width: 690px;
  height: 100%;
  float: left;
  border: 3px solid;
  border-radius: 7px;
  border-color: rgb(193, 208, 189);
}

.meg {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  background-color: #a5541e;
  color: white;
  border-radius: 5px;
}

.el-divider--horizontal {
  margin: 0;
}

.me span {
  display: block;
  height: 40px;
  line-height: 40px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
}

.me span a {
  color: #000;
  text-decoration: none;
}
</style>