<template>
  <div>
    <div class="header">
      <div class="logo">
        <div class="image"><img src="../assets/Image/logo1.png" alt=""></div>
        <div class="title">江|畔|花|寻|步</div>
      </div>
      <div class="navbar">
        <ul>
          <router-link to="/home">
            <li><a>首页</a></li>
          </router-link>
          <router-link to="/exhibit">
            <li><a>展览</a></li>
          </router-link>
          <router-link to="/wen">
            <li><a>文创</a></li>
          </router-link>
          <router-link to="/ticket">
            <li><a>购票</a></li>
          </router-link>
          <router-link to="/book">
            <li><a>约拍</a></li>
          </router-link>
          <router-link to="/problem">
            <li><a>常见问题</a></li>
          </router-link>
          <router-link to="/about">
            <li><a>关于我们</a></li>
          </router-link>
        </ul>
        <div class="search">
          <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="input2" class="elSearch">
          </el-input>
        </div>

        <div class="login">
          <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            size="medium"></el-avatar>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link name">
              江小潘<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-circle-check">登录账号</el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-close">切换账号</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </div>

    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
//scoped 在但组件里面起作用
.header {
  height: 70px;
  background-color: rgb(69, 85, 74);
  line-height: 70px;

}

.logo {
  height: 70px;
  line-height: 70px;
  width: 190px;
  float: left;
}

.image {
  float: left;
}

.title {
  margin-left: 10px;
  float: left;
  font-size: 20px;
  color: beige;
}

.navbar ul {
  list-style-type: none;
  float: left;
  margin: 0;
}

.navbar li {
  display: inline-block;
  font-size: 20px;
  margin-left: 40px;
}

a {
  text-decoration: none;
  color: beige;
}

a:hover {
  color: rgb(176, 148, 101);
}

.search {
  float: left;
}

.el-input {
  margin-left: 50px;
  width: 190px;
}

/deep/ .el-input__inner {
  height: 35px;
  border-radius: 12px;
  background-color: transparent;
}

.login {
  float: left;
  display: flex;
  align-items: center;
  margin-left: 280px;
}

.el-dropdown {
  float: left;
}

.name {

  font-size: 17px;
  margin-left: 10px;
  color: rgb(176, 148, 101);
}
</style>