import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../components/Main.vue'
import Home from '../components/Home.vue'
import Exhibit from '../components/Exhibit.vue'
import Wen from '../components/WenChuan.vue'
import About from '../components/About.vue'
import Book from '../components/Book.vue'
import Problem from '../components/Problem.vue'
import Ticket from '../components/Ticket.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/main' },
  {
    path: '/main',
    component: Main,
    redirect: '/home',
    children: [
      { path: '/home', component: Home },
      { path: '/exhibit', component: Exhibit },
      { path: '/wen', component: Wen },
      { path: '/book', component: Book },
      { path: '/about', component: About },
      { path: '/problem', component: Problem },
      { path: '/ticket', component: Ticket },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
