<template>
  <div>
    <div class="breadCrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>购票</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="header">
      <div class="notice">
        <div class="font">购 票 须 知 <i class="el-icon-reading"></i></div>
        <div class="a">1. 请按照现场的观展提示文明观展，不要进入作品不可进入的区域，或触摸装置不可碰触的部分。</div>
        <div class="a">2. 60周岁以上（含60周岁）老年人凭有效身份证原件，门票半价优惠。</div>
        <div class="a">3. 残疾人凭本人残疾证件，免费参观。陪护人不享受优惠，须购票。</div>
        <div class="a">4. 请勿携带宠物进院参观。请尽量不妨碍其他观众。请保持衣容整洁。不要做出有碍观瞻、有损形象的行为。</div>
        <div class="a">5. 请勿随地吐痰。请勿乱丢垃圾废物请您尊重清洁工人的劳动。让我们共同保持参观环境的清洁有序！</div>
        <div class="a">6. 在您参观时请留意自己携带的物品，提防扒窃。</div>
        <div class="a">7. 如果您在参观中遇到紧急情况，就近寻求工作人员的帮助。</div>
        <div class="a">8. 请不要轻信和接受社会散杂人员派发的各种广告和兜售的商品。严防上当受骗，并欢迎举报。</div>
        <div class="phone">联系热线 ： 0-0125001100</div>
      </div>
      <div class="hot">
        <div class="font">热 销 排 行 榜 <i class="el-icon-trophy"></i></div>
        <div class="b"> <img src="../assets/Image/one.png" alt="">
          <div class="bfont">南华大学雨母校区图书馆展</div><el-button type="warning" size="small">详 情</el-button>
        </div>
        <div class="b"><img src="../assets/Image/two.png" alt="">
          <div class="bfont">南华大学红湘校区图书馆展</div><el-button type="warning" size="small">详 情</el-button>
        </div>
        <div class="b"><img src="../assets/Image/three.png" alt="">
          <div class="bfont">衡阳文化中心展</div><el-button type="warning" size="small">详 情</el-button>
        </div>
        <div class="c">
          <div class="cfont">4 &emsp;&emsp;衡阳美术馆展</div><el-button type="warning" size="small">详 情</el-button>
        </div>
        <div class="c">
          <div class="cfont">5 &emsp;&emsp;长沙文化中心展</div><el-button type="warning" size="small">详 情</el-button>
        </div>
      </div>
    </div>
    <div class="exing">
      正在展出
    </div>
    <div class="ticket">
      <div class="tic">
        <div class="imgs"><img src="../assets/Image/tic1.jpg" alt=""></div>
        <div class="name">"江畔花寻步"——南华大学雨母校区图书馆展</div>
        <div class="time">展览时间 ：2024.3.15 &emsp;9：00 —— 12：00</div>
        <div class="date">
          <div class="score">评分：</div>
          <el-rate v-model="value" disabled show-score text-color="#ff9900" score-template="{value}">
          </el-rate>
        </div>
        <div>
          <div class="money">¥ 50</div>
          <div class="pretic"><a href="">立刻购票</a></div>
        </div>
      </div>
      <div class="tic">
        <div class="imgs"><img src="../assets/Image/tic2.jpg" alt=""></div>
        <div class="name">"江畔花寻步"——南华大学红湘校区图书馆展</div>
        <div class="time">展览时间 ：2024.3.15 &emsp;13:00 —— 17：00</div>
        <div class="date">
          <div class="score">评分：</div>
          <el-rate v-model="value1" disabled show-score text-color="#ff9900" score-template="{value}">
          </el-rate>
        </div>
        <div>
          <div class="money">¥ 40</div>
          <div class="pretic"><a href="">立刻购票</a></div>
        </div>
      </div>
      <div class="tic">
        <div class="imgs"><img src="../assets/Image/tic3.jpg" alt=""></div>
        <div class="name">"江畔花寻步"——衡阳文化中心展</div>
        <div class="time">展览时间 ：2024.3.15 &emsp;9：00 —— 12：00</div>
        <div class="date">
          <div class="score">评分：</div>
          <el-rate v-model="value2" disabled show-score text-color="#ff9900" score-template="{value}">
          </el-rate>
        </div>
        <div>
          <div class="money">¥ 50</div>
          <div class="pretic"><a href="">立刻购票</a></div>
        </div>
      </div>
      <div class="tic">
        <div class="imgs"><img src="../assets/Image/tic4.jpg" alt=""></div>
        <div class="name">"江畔花寻步"——衡阳美术馆展</div>
        <div class="time">展览时间 ：2024.3.15 &emsp;17：00 —— 21：00</div>
        <div class="date">
          <div class="score">评分：</div>
          <el-rate v-model="value3" disabled show-score text-color="#ff9900" score-template="{value}">
          </el-rate>
        </div>
        <div>
          <div class="money">¥ 60</div>
          <div class="pretic"><a href="">立刻购票</a></div>
        </div>
      </div>
    </div>
    <div class="presale">
      即将上线
    </div>
    <div class="presa">
      <div class="tic">
        <div class="imgs"><img src="../assets/Image/tic5.jpg" alt=""></div>
        <div class="name">"江畔花寻步"——长沙文化中心展</div>
        <div class="time">展览时间 ：2024.5.15 &emsp;9：00 —— 12：00</div>
        <div class="date">预定日期 ：2024年4月10日 &emsp;<i class="el-icon-date"></i></div>
        <div>
          <div class="money">¥ 70</div>
          <div class="pretic"><a href="">立刻预定</a></div>
        </div>
      </div>
      <div class="tic">
        <div class="imgs"><img src="../assets/Image/tic6.jpg" alt=""></div>
        <div class="name">"江畔花寻步"——湘潭文化中心展</div>
        <div class="time">展览时间 ：2024.6.10 &emsp;13：00 —— 17：00</div>
        <div class="date">预定日期 ：2024年4月20日 &emsp;<i class="el-icon-date"></i></div>
        <div>
          <div class="money">¥ 70</div>
          <div class="pretic"><a href="">立刻预定</a></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="../assets/Image/bottom.png" alt="">
    </div>
  </div>
</template>

<script>
let a1="zhangsna"
export default {
  data() {
    return {
      value: 4.5,
      value1: 4.1,
      value2: 4.2,
      value3: 3.9
    }
  }
}
</script>

<style lang="less" scoped>
.el-breadcrumb {
  font-size: 16px;
  width: 200px;
  margin-left: 100px;
  margin-bottom: 10px;
  line-height: 25px;
}

.header {
  width: 1330px;
  height: 350px;
  margin: auto;
}

.notice {
  width: 850px;
  height: 100%;
  border-radius: 10px;
  margin-right: 30px;
  float: left;
  color: white;
  background-color: rgba(31, 51, 69, );
}

.hot {
  width: 450px;
  height: 100%;
  border-radius: 10px;
  float: left;
  background-color: rgb(193, 208, 189);
}

.hot button {
  float: right;
  margin-right: 20px;
  border-radius: 10px;
}

.font {
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 15px;

}

.a {
  margin-bottom: 5px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 17px;
  margin-left: 10px;
}

.phone {
  width: 350px;
  margin: auto;
  margin-top: 25px;
  font-size: 22px;
}

.b {
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}

.b img {
  float: left;
  margin-left: 10px;
}

.bfont {
  height: 50px;
  width: 400px;
  line-height: 50px;
  float: left;
  padding-left: 31px;
}

.c {
  height: 50px;
  line-height: 50px;
  margin-left: 19px;
  margin-bottom: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.cfont {
  height: 50px;
  width: 450px;
  line-height: 50px;
  float: left;
}

.exing {
  width: 200px;
  height: 50px;
  margin: auto;
  text-align: center;
  line-height: 50px;
  font-size: 22px;
  margin-top: 50px;
  margin-bottom: 20px;
  border-radius: 18px;
  background-color: rgb(110, 176, 176);
  color: white;
}

.ticket {
  width: 1330px;
  height: 1060px;
  margin: auto;
}

.tic {
  width: 630px;
  height: 490px;
  float: left;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.13)
}

.imgs {
  width: 100%;
  height: 230px;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 35px;
}

.name {
  height: 50px;
  line-height: 50px;
  margin-top: 10px;
  margin-left: 30px;
  width: 570px;
  border-bottom: 1px solid;
  font-size: 18px;
}

.time {
  width: 570px;
  height: 30px;
  line-height: 30px;
  margin-left: 30px;
  margin-top: 20px;
}

.score {
  float: left;
}

.date {
  width: 570px;
  height: 30px;
  line-height: 30px;
  margin-left: 30px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.money {
  height: 45px;
  width: 200px;
  line-height: 45px;
  float: left;
  margin-left: 30px;
  color: rgb(243, 107, 9);
  font-size: 30px;
  margin-right: 230px;
}

.pretic {
  height: 45px;
  width: 120px;
  text-align: center;
  float: left;
  line-height: 45px;
  background-color: rgb(241, 107, 12);
  font-size: 20px;
  border-radius: 5px;
}

.pretic a {
  color: white;
  text-decoration: none;
}

.presale {
  width: 200px;
  height: 50px;
  margin: auto;
  text-align: center;
  line-height: 50px;
  font-size: 22px;
  margin-bottom: 20px;
  border-radius: 18px;
  background-color: antiquewhite;
}

.presa {
  width: 1330px;
  height: 450px;
  margin: auto;
  margin-bottom: 70px;
}
</style>